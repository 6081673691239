<template>
  <VCard>
    <VCardTitle>
      <VRow align="center">
        <VCol cols="6">
          <TTTextField
            v-model="search"
            class="tt-text-body-2"
            placeholder="Поиск"
          >
            <template #append>
              <VIcon>
                fal fa-search
              </VIcon>
            </template>
          </TTTextField>
        </VCol>
        <VCol offset="3" cols="3">
          <TTSelect
            v-model="selectedDateTime"
            placeholder="Выберите диапазон"
            :items="dateTimeList"
          >
            <template #item="{ item }">
              <span
                :class="{ 'tt-light-red--text' : item.danger }"
              >
                {{ item.text }}
                <VIcon
                  v-if="item.danger"
                  size="14"
                  class="tt-light-red--text"
                >
                  far fa-exclamation-circle
                </VIcon>
              </span>
            </template>
          </TTSelect>
        </VCol>
      </VRow>
    </VCardTitle>
    <VRow>
      <VCol>
        <TTChipGroup
          v-model="statusFilter"
          multiple
          class="ma-4 mt-0"
        >
          <TTChip
            v-for="(status, i) in statuses"
            :key="i"
            :value="status"
          >
            {{ status }}
          </TTChip>
        </TTChipGroup>
      </VCol>
    </VRow>
    <VDataTable
      class="elevation-1"
      :headers="headers"
      :items="computedReports"
      :loading="loading"
      :expanded.sync="expanded"
      :search="search"
      :footer-props="{
        itemsPerPageOptions : pagination.itemsPerPageOptions,
        showFirstLastPage : true
      }"
      show-expand
    >
      <template #top>
        <VToolbar flat>
          <VToolbarTitle>Отчеты PDF</VToolbarTitle>

          <VSpacer />

          <slot name="topActions" />
        </VToolbar>
      </template>

      <template #item.actions="{ item }">
        <slot
          name="rowActions"
          :item="item"
        />
      </template>

      <template #item.status="{ item }">
        <VChip
          v-bind="chipStyle(item.status)"
          small
          :outlined="false"
        >
          {{ item.status }}
        </VChip>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="ma-4">
            <div
              v-for="el in Object.keys(item)"
              :key="`${item.id}_${el}`"
              class="d-flex"
            >
              <div
                class="mr-1 font-weight-bold"
              >
                {{ el }}:
              </div>
              <div class="d-flex align-center">
                <div
                  v-if="el !== 'reportUrl'"
                >
                  {{ item[el] }}
                </div>
                <TTBtn
                  v-if="el === 'companyId'"
                  icon
                  x-small
                  class="mt-n1"
                  title="Открыть компанию в новой вкладке"
                  @click="goToCompany(item.companyId)"
                >
                  <VIcon
                    size="12"
                    class="tt-blue--text text-center"
                  >
                    $externalLink
                  </VIcon>
                </TTBtn>

                <RouterLink
                  v-if="el === 'userId'"
                  class="d-inline-flex ml-2 mt-n1 tt-light-blue--text"
                  :to="{
                    name : Names.R_AUTHENTICATION_USER,
                    params : { userId : item.userId }
                  }"
                  target="_blank"
                  title="Открыть пользователя в новой вкладке"
                >
                  <VIcon
                    size="12"
                    class="tt-blue--text valign-middle"
                  >
                    $externalLink
                  </VIcon>
                </RouterLink>
                <a
                  v-if="el === 'reportUrl'"
                  :href="item.reportUrl"
                  target="_blank"
                >
                  {{ item.reportUrl }}
                </a>
              </div>
            </div>
          </div>
        </td>
      </template>
    </VDataTable>
  </VCard>
</template>

<script>
const REPORT_STATUSES = {
  INIT: 'init',
  PENDING: 'pending',
  RUNNING: 'running',
  DONE: 'done',
  REJECTED: 'rejected',
};

export default {
  name: 'ReportsTable',

  inject: ['Names'],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    reports: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Company ID',
          align: 'left',
          value: 'companyId',
        },
        {
          text: 'Тип',
          align: 'left',
          value: 'reportType',
        },
        {
          text: 'Статус',
          align: 'left',
          value: 'status',
          width: '10%',
        },
        {
          text: 'Обновлен',
          align: 'left',
          value: 'updatedAt',
        },
        // {
        //   text: '',
        //   align: 'right',
        //   sortable: false,
        //   value: 'actions',
        // },
      ],
      expanded: [],
      search: '',
      statusFilter: [...Object.values(REPORT_STATUSES)],
      statuses: [...Object.values(REPORT_STATUSES)],
      selectedDateTime: null,
      pagination: {
        page: 1,
        limit: 10,
        itemsPerPageOptions: [10, 50, 100, 500, 1000],
      },
    };
  },

  computed: {
    computedReports() {
      return this.reports.map((report) => {
        const result = {
          id: report.id,
          name: report.name,
          status: report.status,
          reportType: this.getReportType(report.reportType),
          email: report.email,
          companyId: report.cookies[0].value,
          createdAt: this.$dayjs.utc(report.createdAt).format('DD.MM.YYYY HH:mm:ss'),
          updatedAt: this.$dayjs.utc(report.updatedAt).format('DD.MM.YYYY HH:mm:ss'),
          reportUrl: report.reportUrl,
          appUrl: this.getAppUrl(report.appUrl),
          failedPageUrl: report?.rejectReason?.url,
          rejectReason: report?.rejectReason?.reason,
        };

        if (report.reportType === 'tsxass') {
          result.userId = report.userId;
          result.surveyId = report.surveyId;
        }

        if (report.reportType === 'engagement') {
          result.slug = report.slug;
          result.teams = report.teams;
          result.slices = report.slices;
          result.dynamics = report.dynamics;
          result.methodology = report.methodology;
        }

        return result;
      }).filter((report) => this.statusFilter.includes(report.status));
    },
    dateTimeList() {
      return [
        {
          text: '10 минут',
          value: '10m',
        },
        {
          text: '30 минут',
          value: '30m',
        },
        {
          text: '1 час',
          value: '1h',
        },
        {
          text: '3 часа',
          value: '3h',
        },
        {
          text: '6 часов',
          value: '6h',
        },
        {
          text: '12 часов',
          value: '12h',
        },
        {
          text: '24 часа',
          value: '24h',
        },
        {
          text: '2 дня',
          value: '2d',
        },
        {
          text: '7 дней',
          value: '7d',
        },
        {
          text: '14 дней',
          value: '14d',
        },
        {
          text: 'За все время',
          value: 'all',
          danger: true,
        },
      ];
    },
    dateTimeValues() {
      return {
        '10m': this.$dayjs().subtract(10, 'minute').startOf('minute').format(),
        '30m': this.$dayjs().subtract(30, 'minute').startOf('minute').format(),
        '1h': this.$dayjs().subtract(1, 'hour').startOf('hour').format(),
        '3h': this.$dayjs().subtract(3, 'hour').startOf('hour').format(),
        '6h': this.$dayjs().subtract(6, 'hour').startOf('hour').format(),
        '12h': this.$dayjs().subtract(12, 'hour').startOf('hour').format(),
        '24h': this.$dayjs().subtract(24, 'hour').startOf('hour').format(),
        '2d': this.$dayjs().subtract(2, 'day').startOf('day').format(),
        '7d': this.$dayjs().subtract(7, 'day').startOf('day').format(),
        '14d': this.$dayjs().subtract(14, 'day').startOf('day').format(),
        all: '1970-01-01T00:00:00Z',
      };
    },
  },

  watch: {
    '$route.query.status': {
      handler() {
        this.initializeFiltersFromURL();
      },
    },
    statusFilter() {
      this.updateURL();
    },
    selectedDateTime(newVal) {
      this.$emit('update-date-time', this.dateTimeValues[newVal]);
      this.updateURL();
    },
  },

  created() {
    this.initializeFiltersFromURL();
  },

  methods: {
    updateURL() {
      const query = { ...this.$route.query };

      if (this.statusFilter.length) {
        query.status = this.statusFilter.join(',');
      } else {
        delete query.status;
      }

      if (this.selectedDateTime) {
        query.startDate = this.selectedDateTime;
      } else {
        delete query.startDate;
      }

      // Проверяем, изменились ли параметры перед навигацией
      if (this.$route.query.status !== query.status || this.$route.query.startDate !== query.startDate) {
        this.$router.push({ query }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    },
    initializeFiltersFromURL() {
      const {
        status,
        startDate,
      } = this.$route.query;
      if (status) {
        this.statusFilter = status.split(',');
      }
      if (startDate) {
        this.selectedDateTime = startDate;
      } else {
        this.selectedDateTime = '6h';
      }
    },
    async goToCompany(id) {
      try {
        const { company } = await this.$di.api.Account.getCompany({ id });
        const url = this.$router.resolve({
          name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW,
          params: {
            accountId: company.accountId,
            companyId: company.id,
          },
        }).href;
        window.open(url, '_blank');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    getAppUrl(url) {
      return `${url}&page=0&pageStartNumber=0&ymDisabled=true`;
    },
    getReportType(type) {
      let typePostfix = '';
      if (type === 'tsxass') {
        typePostfix = ' (360)';
      }
      if (type === 'engagement') {
        typePostfix = ' (Опросы)';
      }
      return `${type}${typePostfix}`;
    },
    chipStyle(status) {
      const colors = {
        [REPORT_STATUSES.INIT]: 'tt-light-gray',
        [REPORT_STATUSES.PENDING]: 'tt-light-yellow',
        [REPORT_STATUSES.RUNNING]: 'tt-light-blue vibrant',
        [REPORT_STATUSES.DONE]: 'tt-light-green vibrant',
        [REPORT_STATUSES.REJECTED]: 'tt-light-orange',
      };
      return { color: colors[status] };
    },
  },
};
</script>
<style lang="scss" scoped>
.valign-middle {
  vertical-align: middle;
}
</style>
